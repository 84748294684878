/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
var CustomStyledTooltip = styled(function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (_jsx(Tooltip, __assign({}, props, { arrow: true, classes: { popper: className } })));
})(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(tooltipClasses.arrow)] = {
            color: "#333333",
        },
        _b["& .".concat(tooltipClasses.tooltip)] = {
            backgroundColor: "#333333",
            color: "#ffffff",
            font: "normal normal normal 11px/18px Roboto",
        },
        _b);
});
export function MuiTooltip(_a) {
    var title = _a.title, children = _a.children, props = __rest(_a, ["title", "children"]);
    return (_jsx(CustomStyledTooltip, __assign({ arrow: true, placement: "top", title: title }, props, { children: _jsx("button", __assign({ style: { backgroundColor: "transparent", border: "none" } }, { children: children })) })));
}
