/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useMemo, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CheckContorno, IconChecked } from "../Icons";
import { MuiTooltip } from "../Tooltip";
import { MuiTypography } from "../Typography";
import { MuiBox } from "../Box";
export var MuiCheckbox = forwardRef(function (_a, ref) {
    var _b, _c;
    var labeltxt = _a.labeltxt, onChange = _a.onChange, _d = _a.selected, selected = _d === void 0 ? false : _d, _e = _a.defaultColor, defaultColor = _e === void 0 ? "#F58B3C" : _e, disabled = _a.disabled, hasTolltipLabel = _a.hasTolltipLabel, tooltipContent = _a.tooltipContent, hasDashed = _a.hasDashed, reset = _a.reset, props = __rest(_a, ["labeltxt", "onChange", "selected", "defaultColor", "disabled", "hasTolltipLabel", "tooltipContent", "hasDashed", "reset"]);
    var _f = useState(selected), checked = _f[0], setChecked = _f[1];
    useMemo(function () {
        setChecked(selected);
    }, [selected]);
    useMemo(function () {
        if (reset && checked) {
            setChecked(!checked);
        }
    }, [reset, checked]);
    return (_jsx(FormGroup, __assign({ sx: {
            "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: "#4F4F4F",
                marginRight: "0px!important",
            },
            "& .MuiCheckbox-root": {
                color: (_b = "".concat(defaultColor, "!important")) !== null && _b !== void 0 ? _b : "#F58B3C!important",
                padding: "0px!important",
                marginRight: "10px!important",
                position: "relative",
            },
            "& .MuiCheckbox-indeterminate": {
                color: (_c = "".concat(defaultColor, "!important")) !== null && _c !== void 0 ? _c : "#F58B3C!important",
            },
            "& svg": {
                width: checked ? "25px!important" : null,
            },
        } }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, __assign({ icon: disabled ? _jsx(CheckContorno, { size: 25, disabled: true }) : _jsx(CheckContorno, { size: 25 }), checkedIcon: _jsx(MuiBox, __assign({ height: "25px", width: "25px" }, { children: _jsx(IconChecked, { size: 18, disabled: disabled }) })), checked: checked, disabled: disabled, onChange: function (e) {
                    setChecked(e.target.checked);
                    if (onChange) {
                        onChange(e.target.checked);
                    }
                } }, props)), label: _jsx(_Fragment, { children: hasTolltipLabel && tooltipContent && labeltxt ? (_jsx(MuiTooltip, __assign({ title: tooltipContent }, { children: _jsx(MuiTypography, __assign({ variant: "body2", fontSize: 15, fontWeight: 300, color: hasDashed ? defaultColor : "", sx: {
                            borderBottom: hasDashed ? "1px dashed ".concat(defaultColor) : null,
                        } }, { children: labeltxt })) }))) : (_jsx(_Fragment, { children: labeltxt })) }) }) })));
});
export var MuiCheckboxDefault = forwardRef(function (_a, ref) {
    var _b, _c;
    var labeltxt = _a.labeltxt, onChange = _a.onChange, _d = _a.selected, selected = _d === void 0 ? false : _d, defaultColor = _a.defaultColor, disabled = _a.disabled, props = __rest(_a, ["labeltxt", "onChange", "selected", "defaultColor", "disabled"]);
    var _e = useState(selected), checked = _e[0], setChecked = _e[1];
    useMemo(function () {
        setChecked(selected);
    }, [selected]);
    return (_jsx(FormGroup, __assign({ sx: {
            "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                color: "#4F4F4F",
                marginRight: "0px!important",
            },
            "& .MuiCheckbox-root": {
                color: (_b = "".concat(defaultColor, "!important")) !== null && _b !== void 0 ? _b : "#F58B3C!important",
                padding: "0px!important",
                marginRight: "10px!important",
                position: "relative",
            },
            "& .MuiCheckbox-indeterminate": {
                color: (_c = "".concat(defaultColor, "!important")) !== null && _c !== void 0 ? _c : "#F58B3C!important",
            },
        } }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, __assign({ checked: checked, disabled: disabled, onChange: function (e) {
                    setChecked(e.target.checked);
                    if (onChange) {
                        onChange(e.target.checked);
                    }
                } }, props)), label: labeltxt }) })));
});
