/* eslint-disable */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Cards from './components/cards'
import { Controller, useForm } from 'react-hook-form'

// @ Functions
import { GetTypePoints, handleCalculateValue, handleProductDataUpdate } from './Functions'

// @ Styles and Utils
import { CssTextField } from '../../../../Filter/styles'
import { DefaultProductData } from './Functions/utils'

// @ Mui
import { Autocomplete, FormLabel, Grid } from '@mui/material'

// @ Components
import Configuration from './components/Configuration'
import CustomModal from '../../../../../../Modal'
import { ParameterizationByDivision, MultipleParameterizationByDivision } from '../../../Functions'
import { ProductByDivision } from './Functions'
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader'
import CalcMemory from '../../../../CalculationMemory'

const ParameterizeDivisionTB = ({
	productData,
	isMultiple,
	modalParameterization,
	setModalParameterization,
	division,
	onReset,
	viewMode,
	selectedDivisionIdGen,
	pametrizationName,
	grouper,
}) => {
	const { t } = useTranslation()
	const [GenerationType, setGenerationType] = useState([])
	const [parameterization, setParameterization] = useState([])
	const [divisionProductData, setDivisionProductData] = useState({})

	const [listDivision, setListDivision] = useState([])

	const [loading, setLoading] = useState(false)
	const [openCalcMemory, setOpenCalcMemory] = useState(false)

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { control, watch, reset } = useForm({
		defaultValues: {
			typeOfPoints: {
				key: '',
				value: 'Não parametrizado',
			},
			pointsValue: '',
		},
	})

	const { typeOfPoints, pointsValue } = watch()

	const [parameterizationGenerationPoints, setParameterizationGenerationPoints] = useState(parameterization)

	const fetchCalculation = async cashbackPercentualWatch => {
		const generationPoints = await handleCalculateValue(
			productData?.id,
			productData?.price || 0,
			typeOfPoints?.key || 0,
			cashbackPercentualWatch,
			productData?.typeOfRedeem?.key,
			productData?.redemptionPoints?.key,
		)
		if (generationPoints) {
			setParameterizationGenerationPoints(generationPoints)
		}
	}

	const fetchGenerationType = () => {
		GetTypePoints(setGenerationType, setLoading)
	}

	useEffect(() => fetchGenerationType(), [])

	useEffect(() => {
		handleProductDataUpdate(divisionProductData, setParameterization, reset)
	}, [divisionProductData])

	const handlePametrizationChange = () => {
		if (isMultiple) {
			MultipleParameterizationByDivision({
				t,
				productData,
				divisionProductData,
				typeOfPoints,
				pointsValue,
				setSnackStatus,
				selectedDivisionIdGen,
				pametrizationName,
				setLoading,
				grouper,
				onReset,
			})
		} else {
			ParameterizationByDivision({ onReset, grouper, productData, typeOfPoints, pointsValue, setSnackStatus, divisionId: division?.id, setLoading })
		}
	}

	useEffect(() => {
		ProductByDivision({ id: productData?.id, divisionId: division?.id, type: 'Points', setDivisionProductData, setLoading })
	}, [division])
	return (
		<S.CardsContent>
			{loading && <Loader />}
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					handlePametrizationChange()
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			{isMultiple && <S.Line />}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Configuração do benefício*')}</span>
						<Controller
							name="typeOfPoints"
							control={control}
							render={({ value, onChange }) => (
								<Autocomplete
									id="combo-box-demo"
									options={GenerationType}
									getOptionLabel={option => (option.value ? t(option.value) : '')}
									disableClearable={true}
									disablePortal
									disabled={viewMode}
									value={value}
									fullWidth
									onChange={(_event, newValue) => onChange(newValue)}
									renderInput={params => <CssTextField {...params} variant="outlined" size="small" />}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				{/* <Grid item xs={12} sm={9}>
					{typeOfPoints?.key !== '0' && (
						<Cards
							calculationMemory={parameterization}
							typeOfPoints={typeOfPoints?.key}
							setListDivision={setListDivision}
							onOpen={() => setOpenCalcMemory(!openCalcMemory)}
						/>
					)}
				</Grid> */}
			</Grid>
			<Grid item xs={12} sm={12}>
				{typeOfPoints?.key === '3' && (
					<Configuration
						viewMode={viewMode}
						calculationMemory={parameterization}
						parameterizationGenerationPoints={parameterizationGenerationPoints}
						fetchCalculation={fetchCalculation}
						control={control}
						pointsValue={pointsValue}
						typeOfPoints={typeOfPoints?.key}
						divisionProductData={divisionProductData}
						listDivision={listDivision}
						onOpen={() => setOpenCalcMemory(!openCalcMemory)}
					/>
				)}
			</Grid>
			<CalcMemory open={openCalcMemory} productData={productData ?? []} onClose={() => setOpenCalcMemory(!openCalcMemory)} />
		</S.CardsContent>
	)
}

ParameterizeDivisionTB.propTypes = {
	grouper: PropTypes.number,
	productData: PropTypes.object,
	isMultiple: PropTypes.bool,
	modalParameterization: PropTypes.object,
	setModalParameterization: PropTypes.func,
	division: PropTypes.object,
	viewMode: PropTypes.bool,
	onReset: PropTypes.func,
	setViewMode: PropTypes.func,
	selectedDivisionIdGen: PropTypes.number,
	pametrizationName: PropTypes.string,
}

ParameterizeDivisionTB.defaultProps = {
	divisionProductData: DefaultProductData,
	isMultiple: false,
}

export default ParameterizeDivisionTB
