// Material
import { Grid } from '@material-ui/core'
// Components
import Section from 'components/Section'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../../../../../../components/CustomMaterialTable/CustomMaterialTable'
import { GetBankOperations } from '../../../../../../redux/api/accounts'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import * as S from './styles'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { numberFormatText, convertUTCToLocal } from 'utils/utils'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import Button from 'components/CustomButtons/Button.jsx'
import {envs}  from '../../../../../../redux/api/windowServerData'



export function ModalDebit({ isOpen, debitData }) {
	const { closeDebit } = useAccounts()
	const { t } = useTranslation()
	const { id } = useParams()

	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const handleGetDebitOperations = useCallback(async (pageSize, page, search, orderBy, orderDirection) => {
		const res = await GetBankOperations(id, null, null, null, 3, null, null, pageSize, page)

		return res
	}, [])

	return (
		<Layout isOpen={isOpen}>
			<S.ContainForm>
				<S.SectionBox>
					<Section title={t('PERFORMANCE_CAMPAIGN_CARD_MOVIMENTATION')} style={{ flexGrow: 1, marginRight: ' 0.938rem' }} />

					<Button onClick={() => closeDebit()} color="greenButtonClose" style={{ margin: 0 }} size="sm">
						<i className="icon-ico_cancelar" style={{ marginRight: '.688rem' }} />
						Fechar
					</Button>
				</S.SectionBox>

				<Grid container spacing={2} xs={12} sm={12}>
					<Grid container spacing={2} xs={12} sm={12}>
						<Grid item xs={3} sm={3}>
							<S.Title themeColor={themeColor}>Data</S.Title>
							<div>{moment(convertUTCToLocal(debitData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}</div>
							<div>{moment(convertUTCToLocal(debitData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('HH:mm')}</div>
						</Grid>
						<Grid item xs={3} sm={3}>
							<S.Title themeColor={themeColor}>Operação</S.Title>
							<div>{debitData.operation && t(debitData.operation.value)}</div>
						</Grid>
						<Grid item xs={3} sm={3}>
							<S.Title themeColor={themeColor}>Reserva utilizada</S.Title>
							<div>{debitData.description}</div>
						</Grid>

						<Grid item xs={3}>
							<S.Title themeColor={themeColor}>Distribuição</S.Title>
							<div>{debitData.bankAccountReserve}</div>
						</Grid>

						<Grid item xs={3} sm={3}>
							<S.Title themeColor={themeColor}>Valor</S.Title>
							<div
								style={{
									display: 'flex',
								}}
							>
								<S.IconIcoDebitado className="icon-ico_debitado" />
								<p>
									{numberFormatText(
										debitData.value ? debitData.value : '0',
										localStorage.getItem('currencySymbol')
											? `${localStorage.getItem('currencySymbol')}\n`
											: // : envs
											  // 	.REACT_APP_CURRENCY_SYMBOL,

											  envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
								</p>
							</div>
						</Grid>
						<Grid item xs={3} sm={3}>
							<S.Title themeColor={themeColor}>Status</S.Title>
							<div>{debitData.status && t(debitData.status.value)}</div>
						</Grid>
						<Grid item xs={6} sm={6}></Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ marginTop: '20px' }}>
					<CustomMaterialTable
						style={{
							width: '100%',
							borderBottom: '2px solid #C8C8C8',
							borderRadius: '10px 10px 0 0',
						}}
						noToolbar
						options={{
							emptyRowsWhenPaging: false,
							search: false,
							toolBar: false,
							pageSize: 5,
							rowStyle: {
								backgroundColor: '#fff',
							},
						}}
						columns={[
							{
								title: 'Data',
								field: 'date',
								width: '37.5%',
								render: props => '20/10/2021',
							},
							{
								title: 'CPF/CNPJ',
								field: 'cpf',
								width: '37.5%',
								cellStyle: {
									textAlign: 'left',
								},
								headerStyle: {
									textAlign: 'left',
								},

								render: props => '05265617930',
							},
							{
								title: 'Valor',
								field: 'value',
								width: '5%',
								render: props => (
									<span
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}
									>
										{numberFormatText(
											props.value ? props.value : '0',
											// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											2,
										)}
									</span>
								),
							},
						]}
						data={query =>
							new Promise((resolve, reject) => {
								handleGetDebitOperations(query.pageSize, query.page + 1, query.search, query.orderBy, query.orderDirection).then(result => {
									if (result) {
										if (result.data) {
											resolve({
												data: result.data.results,
												page: result.data.currentPage - 1,
												totalCount: result.data.recordCount,
												pageSize: result.data.pageSize,
											})
										}
									}
								})
							})
						}
					/>
				</Grid>
			</S.ContainForm>
		</Layout>
	)
}
