/* eslint-disable import/first */
export function formatCPF(cpf) {
    var _a;
    return (_a = cpf === null || cpf === void 0 ? void 0 : cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")) !== null && _a !== void 0 ? _a : "-";
}
export function formatCNPJ(cnpj) {
    var _a;
    return (_a = cnpj === null || cnpj === void 0 ? void 0 : cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")) !== null && _a !== void 0 ? _a : "-";
}
export function formatCellphone(phone) {
    if ((phone === null || phone === void 0 ? void 0 : phone.length) === 10) {
        // Format Phone (XX) XXXX-XXXX
        return phone === null || phone === void 0 ? void 0 : phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    else if ((phone === null || phone === void 0 ? void 0 : phone.length) === 11) {
        // Format Cellphone (XX) XXXXX-XXXX
        return phone === null || phone === void 0 ? void 0 : phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
    else {
        // Unmatched format
        return phone !== null && phone !== void 0 ? phone : "-";
    }
}
export function formatDate(data) {
    var partesData = data === null || data === void 0 ? void 0 : data.split("-");
    if (partesData.length === 3) {
        return "".concat(partesData[2], "/").concat(partesData[1], "/").concat(partesData[0]);
    }
    else {
        return data !== null && data !== void 0 ? data : "-";
    }
}
export function formatUSDCurrency(value) {
    var currencyMask = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    if (Number(value) > 10) {
        return "SD$ ".concat(currencyMask.format(value).replace("$", ""));
    }
    else {
        return "SD$ ".concat(value);
    }
}
export function formatBRCurrency(value) {
    var currencyMask = new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
    });
    return currencyMask.format(value);
}
export function clearMask(value) {
    if (value) {
        return value.replace(/[^\d]/g, "");
    }
    return null;
}
