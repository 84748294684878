/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, TextField } from "@mui/material";
import { MuiTypography } from "../Typography";
import { AlertModalIcon } from "../Icons";
import { MuiStack } from "../Stack";
import { MuiBox } from "../Box";
export function MuiAutocomplete(_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, defaultColor = _a.defaultColor, required = _a.required, standard = _a.standard, label = _a.label, width = _a.width, backgroundColor = _a.backgroundColor, value = _a.value, onChange = _a.onChange, disabled = _a.disabled, textLabel = _a.textLabel, labelColor = _a.labelColor, showLabel = _a.showLabel, showError = _a.showError, InputProps = _a.InputProps, errorText = _a.errorText, defaultValue = _a.defaultValue, placeholder = _a.placeholder, 
    // onInputChange,
    noOptionsText = _a.noOptionsText, onInput = _a.onInput, field = _a.field, helperText = _a.helperText, error = _a.error, sx = _a.sx, props = __rest(_a, ["options", "defaultColor", "required", "standard", "label", "width", "backgroundColor", "value", "onChange", "disabled", "textLabel", "labelColor", "showLabel", "showError", "InputProps", "errorText", "defaultValue", "placeholder", "noOptionsText", "onInput", "field", "helperText", "error", "sx"]);
    var color = defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C";
    // const [selectOptions, setSelectOptions] = useState<Option[]>([]);
    // useEffect(() => {
    //   if (options) {
    //     setSelectOptions(options);
    //   }
    // }, [options]);
    var handleChange = function (event, newValue) {
        if (onChange) {
            onChange(event, newValue);
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Autocomplete
        // key={selectOptions?.map((option) => option.id).join(",")}
        , { "data-testid": standard ? "mui-input-standard" : "mui-input", disablePortal: true, id: "combo-box-demo", options: options, value: value, onChange: handleChange, getOptionLabel: function (option) { return option.label || ""; }, disabled: disabled, defaultValue: defaultValue, 
            // onInputChange={onInputChange}
            onInput: onInput, noOptionsText: noOptionsText, sx: __assign(__assign({}, sx), { width: width !== null && width !== void 0 ? width : "100%", height: "85px", 
                // backgroundColor: backgroundColor ?? "#FFFFFF",
                ".MuiAutocomplete-endAdornment": {
                    display: showError && "none",
                } }), renderInput: function (params) {
                var _a;
                return (_jsxs(MuiStack, __assign({ sx: {
                        ".MuiTypography-body2": {
                            height: "25px!important",
                        },
                    } }, { children: [showLabel && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: labelColor !== null && labelColor !== void 0 ? labelColor : "#989898", style: {
                                    height: "25px",
                                    fontSize: "13px",
                                } }, { children: textLabel !== null && textLabel !== void 0 ? textLabel : " " })) }))), _jsxs(MuiStack, __assign({ direction: "row", alignItems: "center" }, { children: [_jsx(TextField, __assign({}, params, { "data-testid": standard ? "mui-input-standard" : "mui-input", size: "small", variant: standard ? "standard" : "outlined", disabled: disabled, placeholder: placeholder, error: error, helperText: helperText !== null && helperText !== void 0 ? helperText : "", value: value, InputLabelProps: {
                                        shrink: true,
                                    }, sx: __assign(__assign({}, sx), { backgroundColor: (_a = ((disabled && "#F2F2F2") || backgroundColor)) !== null && _a !== void 0 ? _a : "#FFFFFF", "& label.Mui-focused": {
                                            color: color,
                                        }, "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: color,
                                            },
                                        }, "& .MuiInputBase-root.Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root": {
                                            borderColor: color,
                                        }, "& .MuiInput-underline.Mui-focused:after": {
                                            borderBottomColor: color,
                                        } }), fullWidth: true }, props)), showError && (_jsx(MuiBox, __assign({ "data-testid": "error-icon", sx: {
                                        position: "relative",
                                        left: "-35px",
                                        top: "4px",
                                        width: "1px",
                                    } }, { children: _jsx(AlertModalIcon, { size: 22, color: "#BE2C2C" }) })))] })), showError && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#BE2C2C" }, { children: errorText !== null && errorText !== void 0 ? errorText : " " })) })))] })));
            } }) }));
}
