import React, { useEffect, useMemo, useState } from 'react'
import DocumentTitle from 'react-document-title'

import { useTranslation } from 'react-i18next'

import _, { set } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
	Breadcrumb,
	MuiBox,
	Button,
	MuiStack,
	MuiTypography,
	GridContainer,
	GridItem,
	MuiInput,
	LabelText,
	MuiAutocomplete,
	AlertModalIcon,
	MuiInputSearch,
	InputPercentage,
	InputUSDCurrency,
	InputPtBRCurrency,
	MuiSnack,
	InputCPForCNPJ,
	formatCPF,
	MuiCheckbox,
	MuiDatePicker,
	MuiIconButton,
	MuiTooltip,
	MuiLoader,
	CheckListDivisionV2,
	CreateNegotiationOrigin,
	InputCPF,
} from '../../../components/storybook'
import { Controller, useForm } from 'react-hook-form'
import { getDefaultColor } from '../../Partner/PartnerProfile/Functions'
import { Divider } from '@mui/material'
import { useCreateNegotiation } from './Hooks/useCreateNegotiation'
import { formatDate } from '../Negotiation/Functions'
import { ConfirmNegotiationModal } from './Components/ConfirmNegotiationModal'

import { CancelNegotiationModal } from './Components/CancelNegotiationModal'
import { CreateCampainModal } from './Components/CreateCampainModal'
import { validationMessages } from './Mocks'
import { format } from 'date-fns'
import getDivisionOptions, { getProductOptions } from './Functions'
import { getProgramTitle } from '../../../utils/utils'
import { LimitInformation } from './Components/LimitInformation/indes'

export function CreateNegotiation() {
	const { t } = useTranslation()
	const history = useHistory()
	const {
		getPartnerBranches,
		partnerBranches,
		getProductGroups,
		productGroups,
		getProducts,
		productInfos,
		validateDocument,
		isInvalidDocument,
		setIsInvalidDocument,
		negotiatorInfos,
		getGroupData,
		groupData,
		resetDisabledFields,
		startedNegociation,
		setStartednegociation,
		customDisableFields,
		setCustomDisableFields,
		changeCustomDisableFields,
		openModalConfirmNegotiation,
		openModalCancelNegotiation,
		openAndCloseModalConfirmNegotiation,
		openAndCloseModalCancelNegotiation,
		validateSelectedGroupItem,
		createNegotiation,
		notificationMessage,
		handleCloseStack,
		benefitOptions,
		benefitGroupOtpions,
		defaultBeneftitOption,
		hasActiveNegotiation,
		setHasActiveNegotiation,
		onClearSearch,
		setOnClearSearch,
		getNegotiationsCampains,
		negotiationCampains,
		openAndCloseModalCreateCampain,
		openModalCreateCampain,
		createNegotiationsCampains,
		errorMessage,
		setErrorMessage,
		isLoading,
		setIsLoading,
		selectedDivisions,
		setSelectedDivisions,
		getProductsDebounce,
		getProgramRules,
		programRules,
	} = useCreateNegotiation()

	const { control, handleSubmit, setValue, watch, reset } = useForm()
	const watchFields = watch()
	const defaultColor = getDefaultColor()

	useMemo(() => {
		if (!watchFields?.selectGroup?.label === 'Produto' && !watchFields?.productSKU && !watchFields?.productName) {
			setCustomDisableFields(prev => {
				return {
					...prev,
					benefit: true,
					benefitGroup: true,
					value: true,
					date: true,
					hour: true,
					sellerName: true,
					description: true,
				}
			})
		}
	}, [])

	useMemo(() => {
		if (!watchFields?.group?.label !== 'Produto' && !watchFields?.selectGroup?.label) {
			setCustomDisableFields(prev => {
				return {
					...prev,
					benefit: true,
				}
			})
		}
	}, [watchFields?.group?.label, watchFields?.selectGroup?.label])

	useEffect(() => {
		Promise.all([getProgramRules(), getPartnerBranches(), getProducts(), getNegotiationsCampains()])
	}, [])

	useEffect(() => {
		getProductGroups(t)
	}, [])

	useMemo(() => {
		if (watchFields?.productSKU) {
			setValue('productName', productInfos?.name)
		} else if (watchFields?.productName) {
			setValue('productSKU', productInfos?.sku)
		}
	}, [productInfos])

	function validateFielsNotEmpty() {
		return !Boolean(
			startedNegociation &&
				watchFields?.startDate &&
				watchFields?.endDate &&
				watchFields?.benefit &&
				watchFields?.benefitGroup &&
				watchFields?.value &&
				watchFields?.campain?.value &&
				watchFields?.groups &&
				(watchFields?.unlimitedNF || watchFields?.quantity || watchFields?.unlimitedItems || watchFields?.partialConsumption),
		)
	}

	function submitForm(data) {
		if (!validateFielsNotEmpty()) {
			createNegotiation(data)
		}
	}

	return (
		<MuiBox minHeight="180vh">
			<form id="negotiation-form" onSubmit={handleSubmit(submitForm)}>
				{/******* Layout custom behaviors ********/}
				{notificationMessage && (
					<MuiSnack
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						open={notificationMessage?.open}
						autoHideDuration={6000}
						closeNotification={handleCloseStack}
						message={notificationMessage?.message}
						onClose={handleCloseStack}
						type={notificationMessage?.type ?? 'success'}
					/>
				)}

				<ConfirmNegotiationModal
					open={openModalConfirmNegotiation}
					handleClose={() => openAndCloseModalConfirmNegotiation()}
					// onConfirm={() => createNegotiation()}
				/>

				<CancelNegotiationModal
					open={openModalCancelNegotiation}
					handleClose={() => openAndCloseModalCancelNegotiation()}
					onConfirm={() => history.push('/negotiation')}
				/>

				<CreateCampainModal
					open={openModalCreateCampain}
					defaultColor={defaultColor}
					handleClose={openAndCloseModalCreateCampain}
					onConfirm={createNegotiationsCampains}
				/>

				<MuiBox
					sx={{
						minHeight: '170vh',
						'.MuiTypography-body2': {
							height: '25px !important',
						},
					}}
				>
					<DocumentTitle title={getProgramTitle()} />

					{/******* Action buttons ********/}
					<MuiStack direction="row" justifyContent="space-between">
						<Breadcrumb hrf={[]} items={[t('MENU_MOVEMENT'), t('NEGOTIATION.NEGOTIATION'), 'Nova negociação']} />
						<MuiStack direction="row" spacing={1} justifyContent="space-between">
							<Button
								customType="backPage"
								typeVariation="CustomIconButton"
								disabled={startedNegociation}
								onClick={() => history.push('/negotiation')}
							/>

							<Button
								customType="cancel"
								typeVariation="CustomIconButton"
								disabled={!startedNegociation}
								onClick={() => openAndCloseModalCancelNegotiation()}
							/>
							<Button
								customType="saveSuccess"
								typeVariation="CustomIconButton"
								disabled={validateFielsNotEmpty()}
								onClick={() => openAndCloseModalConfirmNegotiation()}
							/>
						</MuiStack>
					</MuiStack>

					<GridContainer spacing={2} alignItems="center">
						<GridItem xs={3} sm={12} md={3}>
							<Controller
								name="negotiatorDocument"
								control={control}
								render={({ value, onChange }) => (
									<>
										{programRules?.hasCorporationPartners ? (
											<InputCPForCNPJ
												key={onClearSearch || isInvalidDocument}
												textLabel={watchFields?.negotiatorDocument ? t('CREATE_NEGOTIATION.CPF_OR_CNPJ') : ' '}
												showLabel
												defaultColor={defaultColor}
												onClear={onClearSearch}
												InputProps={{
													endAdornment: isInvalidDocument && <AlertModalIcon size={22} color="#BE2C2C" />,
												}}
												onChange={e => {
													onChange(e)
													setIsInvalidDocument(false)
													setStartednegociation(false)
													setErrorMessage(null)
												}}
												value={value}
												placeholder={t('CREATE_NEGOTIATION.CPF_OR_CNPJ')}
												showError
												errorText={
													(isInvalidDocument && errorMessage && validationMessages[errorMessage]) ||
													(!startedNegociation && errorMessage && 'Participante não encontrado')
												}
											/>
										) : (
											<InputCPF
												key={onClearSearch || isInvalidDocument}
												textLabel={watchFields?.negotiatorDocument ? t('CREATE_NEGOTIATION.CPF_OR_CNPJ') : ' '}
												showLabel
												defaultColor={defaultColor}
												onClear={onClearSearch}
												InputProps={{
													endAdornment: isInvalidDocument && <AlertModalIcon size={22} color="#BE2C2C" />,
												}}
												onChange={e => {
													onChange(e)
													setIsInvalidDocument(false)
													setStartednegociation(false)
													setErrorMessage(null)
												}}
												value={value}
												placeholder={t('CREATE_NEGOTIATION.CPF_OR_CNPJ')}
												showError
												errorText={
													(isInvalidDocument && errorMessage && validationMessages[errorMessage]) ||
													(!startedNegociation && errorMessage && 'Participante não encontrado')
												}
											/>
										)}
									</>
								)}
							/>
						</GridItem>
						<GridItem xs={4} sm={6} md={4}>
							<MuiStack direction="row" spacing={1} mt={0.7}>
								{!startedNegociation && (
									<Button
										defaultColor={defaultColor}
										text={t('CREATE_NEGOTIATION.START_NEGOTIATION')}
										disabled={!watchFields?.negotiatorDocument || startedNegociation}
										textColor="#fff"
										typeVariation="CustomTextButton"
										customSize="180px"
										borderRadius={1}
										onClick={() => {
											validateDocument(watchFields?.negotiatorDocument)
											if (!startedNegociation && !isInvalidDocument) {
												setStartednegociation(true)
											}
										}}
									/>
								)}
								{startedNegociation && (
									<Button
										text={t('CREATE_NEGOTIATION.CLEAR')}
										typeVariation="CleanButton"
										disabled={!watchFields?.negotiatorDocument}
										onClick={() => {
											setOnClearSearch(Date.now())
											setValue('negotiatorDocument', null)
											setErrorMessage(null)
											setStartednegociation(false)
											resetDisabledFields()
											reset()
										}}
									/>
								)}
							</MuiStack>
						</GridItem>
					</GridContainer>

					{isLoading && (
						<MuiBox pt={30}>
							<MuiLoader />
						</MuiBox>
					)}

					{!isLoading && startedNegociation && negotiatorInfos && !isInvalidDocument && (
						// {startedNegociation && (
						<>
							{/******* Consumer data ********/}
							<GridContainer spacing={2} pt={2} pb={2} mb={2}>
								<GridItem lg={12} mb={1}>
									<MuiTypography variant="body1" fontWeight={600} color={defaultColor}>
										Dados do participante
									</MuiTypography>
								</GridItem>
								<GridItem xs={4} sm={6} md={4}>
									<LabelText label={t('CREATE_NEGOTIATION.CPF')} value={formatCPF(negotiatorInfos?.cpf) ?? '-'} />
								</GridItem>
								<GridItem xs={4} sm={6} md={4}>
									<LabelText label={t('CREATE_NEGOTIATION.NAME')} value={negotiatorInfos?.name ?? '-'} />
								</GridItem>
								<GridItem xs={4} sm={6} md={4}>
									<LabelText label={t('CREATE_NEGOTIATION.MEMBER_SINCE')} value={formatDate(negotiatorInfos?.createdAt ?? null) ?? '-'} />
								</GridItem>
								<GridItem xs={4} sm={6} md={4} mt={3}>
									<LabelText label={t('CREATE_NEGOTIATION.MOBILE')} value={negotiatorInfos?.phoneNumber || negotiatorInfos?.phoneNumber2 || '-'} />
								</GridItem>
								<GridItem xs={4} sm={6} md={4} mt={3}>
									<LabelText label={t('CREATE_NEGOTIATION.EMAIL')} value={negotiatorInfos?.email ?? '-'} />
								</GridItem>
							</GridContainer>
							<Divider />
							{/******* Negotiation Form ********/}
							<GridContainer spacing={2} pt={2} mt={1}>
								<GridItem lg={12} mb={2}>
									<MuiTypography variant="body1" fontWeight={600} color={defaultColor}>
										{t('CREATE_NEGOTIATION.NEW_NEGOTIATION')}
									</MuiTypography>
								</GridItem>
							</GridContainer>
							<GridContainer spacing={2}>
								<GridItem lg={12} mt={2}>
									<MuiTypography variant="body1">Data de negociação</MuiTypography>
								</GridItem>
								<GridItem
									lg={3}
									sx={{
										'& .MuiStack-root': {
											paddingTop: '0px!important',
										},
									}}
								>
									<Controller
										control={control}
										name="startDate"
										render={({ onChange, ...field }) => (
											<MuiDatePicker
												{...field}
												defaultColor={defaultColor}
												autoComplete="off"
												showLabel
												textLabel="De*"
												width="100%"
												minDate={format(new Date(), 'yyyy-MM-dd')}
												onChange={value => {
													if (value) {
														onChange(new Date(value).toISOString())
														changeCustomDisableFields('endDate', !customDisableFields?.endDate)
													}
												}}
											/>
										)}
									/>
								</GridItem>
								<GridItem
									lg={3}
									sx={{
										'& .MuiStack-root': {
											paddingTop: '0px!important',
										},
									}}
								>
									<Controller
										control={control}
										name="endDate"
										render={({ onChange, ...field }) => (
											<MuiDatePicker
												{...field}
												defaultColor={defaultColor}
												autoComplete="off"
												showLabel
												width="100%"
												textLabel="Até*"
												minDate={format(new Date(), 'yyyy-MM-dd')}
												// disabled={customDisableFields?.endDate}
												onChange={value => {
													changeCustomDisableFields('campain', false)
													if (value) {
														onChange(new Date(value).toISOString())
													}
												}}
											/>
										)}
									/>
								</GridItem>
								<GridItem lg={6}>
									<MuiStack direction="row" spacing={2}>
										<Controller
											control={control}
											name="campain"
											render={({ onChange, value }) => (
												<MuiAutocomplete
													textLabel={watchFields?.campain?.label ? 'Origem da negociação*' : ' '}
													showLabel
													defaultColor={defaultColor}
													value={value}
													// disabled={customDisableFields?.campain}
													onChange={(_e, newValue) => {
														onChange(newValue)
													}}
													options={negotiationCampains ?? []}
													placeholder="Origem da negociação*"
													noOptionsText="Não há origens cadastradas"
												/>
											)}
											defaultValue={[]}
										/>
										<MuiBox height="68px" alignItems="end" display="flex">
											<MuiTooltip title="Crie uma origem para esta negociação.">
												<MuiIconButton
													// disabled={customDisableFields?.campain}
													onClick={openAndCloseModalCreateCampain}
												>
													<CreateNegotiationOrigin
														size={28}
														// color={customDisableFields?.campain ? '#989898' : defaultColor}
														color={defaultColor}
													/>
												</MuiIconButton>
											</MuiTooltip>
										</MuiBox>
									</MuiStack>
								</GridItem>
							</GridContainer>
							<GridContainer spacing={2} pt={2} pb={1} alignItems="center">
								<GridItem lg={4}>
									<MuiBox
										sx={{
											label: {
												marginLeft: '0px!important',
												marginRight: '0px!important',
											},
										}}
									>
										<Controller
											name="hasContract"
											control={control}
											render={({ value, onChange }) => (
												<MuiCheckbox
													labeltxt="Essa negociação possui contrato"
													// disabled={customDisableFields?.campain}
													onChange={e => {
														onChange(e)
													}}
													selected={value}
												/>
											)}
										/>
									</MuiBox>
								</GridItem>
								<GridItem lg={4}>
									<Controller
										name="contractNumber"
										control={control}
										render={({ value, onChange }) => (
											<MuiInput
												autoComplete="off"
												textLabel={watchFields?.contractNumber ? 'Número de contrato*' : ' '}
												showLabel
												defaultColor={defaultColor}
												disabled={!watchFields?.hasContract}
												onChange={e => onChange(e)}
												value={value}
												placeholder="Número de contrato*"
											/>
										)}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer alignItems="end" spacing={2}>
								<GridItem
									xs={7}
									sm={12}
									md={7}
									mt={2}
									mb={3}
									sx={{
										'.MuiList-root': {
											width: '290px',
										},

										'.MuiListItemText-root span > div > div': {
											minWidth: '5px!important',
										},

										// '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root': {
										// 	backgroundColor: customDisableFields?.campain ? '#f2f2f2!important' : 'transparent',
										// },
									}}
								>
									<CheckListDivisionV2
										addTextLabel="Adicionar todas"
										defaultOptions={getDivisionOptions(selectedDivisions)}
										// disabled={customDisableFields?.campain}
										fieldName="businessDivisions"
										getSelectedItems={selectedItems => {
											if (selectedItems) {
												setValue('businessDivisions', selectedItems)
												setSelectedDivisions(selectedItems)
												selectedItems?.length > 0 ? changeCustomDisableFields('groups', false) : changeCustomDisableFields('groups', true)
												setErrorMessage(false)
											}

											if (selectedItems && watchFields?.benefit?.label && !errorMessage) {
												changeCustomDisableFields('benefitGroup', false)
											}
										}}
										label="Divisão(ões) de negócio(s)*"
										options={getDivisionOptions(partnerBranches)}
										removeTextLabel="Deletar todas"
										selectorLabel="Evento(s)"
									/>
								</GridItem>
							</GridContainer>
							<GridContainer alignItems="end" spacing={2}>
								<GridItem xs={4} sm={12} md={4} mt={1}>
									<Controller
										control={control}
										name="groups"
										render={({ onChange, value }) => (
											<MuiAutocomplete
												textLabel={watchFields?.groups?.label ? t('CREATE_NEGOTIATION.AGGREGATOR') : ' '}
												showLabel
												defaultColor={defaultColor}
												value={value}
												// disabled={customDisableFields?.groups}
												onChange={(_e, newValue) => {
													onChange(newValue)
													getGroupData(newValue?.value)
													setValue('selectGroup', null)
													setValue('benefit', null)
													changeCustomDisableFields('selectGroup', false)
													setHasActiveNegotiation(false)

													if (newValue?.label === 'Produto') {
														changeCustomDisableFields('productSKU', false)
														changeCustomDisableFields('productName', false)
													}
												}}
												options={productGroups}
												placeholder={t('CREATE_NEGOTIATION.AGGREGATOR')}
												noOptionsText="Não há grupos cadastrados"
											/>
										)}
										defaultValue={[]}
									/>
								</GridItem>
								<GridItem xs={4} sm={12} md={4}>
									{watchFields?.groups?.label && watchFields?.groups?.label !== 'Produto' && (
										<Controller
											control={control}
											name="selectGroup"
											render={({ onChange, value }) => (
												<MuiAutocomplete
													textLabel={
														(watchFields?.groups?.label &&
															`Selecione ${watchFields?.groups?.label === 'Fabricante' ? 'o' : 'a'} ${watchFields?.groups?.label} `) ??
														' '
													}
													showLabel
													defaultColor={defaultColor}
													autoComplete="off"
													disabled={customDisableFields?.selectGroup}
													backgroundColor={customDisableFields?.selectGroup && '#F2F2F2'}
													value={value}
													onChange={(_e, newValue) => {
														onChange(newValue)
														setHasActiveNegotiation(false)

														changeCustomDisableFields('benefit', false)
														changeCustomDisableFields('benefitGroup', true)
													}}
													options={groupData ?? []}
													placeholder={
														watchFields?.groups?.label &&
														`Selecione ${watchFields?.groups?.label === 'Fabricante' ? 'o' : 'a'} ${watchFields?.groups?.label} `
													}
													noOptionsText={`Não há ${watchFields?.groups?.label}s ${
														watchFields?.groups?.label === 'Fabricante' ? 'cadastrados' : 'cadastradas'
													} `}
												/>
											)}
											defaultValue={[]}
										/>
									)}
								</GridItem>
							</GridContainer>
							{/******* IF Type product ********/}
							{watchFields?.groups?.label === 'Produto' && (
								<>
									<GridContainer alignItems="end" spacing={2} mt={2} mb={1}>
										<GridItem xs={8} sm={12} md={8}>
											<Controller
												control={control}
												name="productSKU"
												render={({ onChange, value }) => (
													<MuiAutocomplete
														textLabel={watchFields?.campain?.label ? 'Código (SKU) / Produto*' : ' '}
														showLabel
														defaultColor={defaultColor}
														value={value}
														// disabled={customDisableFields?.campain}
														onChange={(_event, inputValue) => {
															setValue('productSKU', inputValue)
															changeCustomDisableFields('benefit', false)
															changeCustomDisableFields('unlimitedItems', false)
														}}
														options={getProductOptions(productInfos) ?? []}
														placeholder="Código (SKU) / Produto*"
														onInput={e => {
															if (e?.target?.value?.length > 1) {
																getProductsDebounce(e?.target?.value)
															}
														}}
														loader
														noOptionsText="Não há produtos cadastrados"
													/>
												)}
												defaultValue={[]}
											/>
										</GridItem>
									</GridContainer>
								</>
							)}
							{/******* Quantity Check ********/}
							{watchFields?.groups?.label && (
								<GridContainer spacing={2} alignItems="center" mt={1}>
									{watchFields?.groups?.label !== 'Produto' && (
										<>
											<GridItem xs={2} sm={6} md={2}>
												<Controller
													name="nfLimit"
													control={control}
													render={({ value, onChange }) => (
														<MuiInput
															textLabel={watchFields?.nfLimit ? 'Qtde de notas fiscais*' : ' '}
															showLabel
															defaultColor={defaultColor}
															disabled={customDisableFields?.nfLimit}
															onChange={e => onChange(e)}
															value={value}
															placeholder="Qtde de notas fiscais*"
														/>
													)}
												/>
											</GridItem>
											<GridItem sx={2} sm={6} md={2}>
												<MuiBox
													sx={{
														label: {
															marginLeft: '0px!important',
															marginRight: '0px!important',
														},
													}}
												>
													<Controller
														name="unlimitedNF"
														control={control}
														render={({ value, onChange }) => (
															<MuiCheckbox
																labeltxt="NFs ilimitados"
																onChange={e => {
																	onChange(e)
																	setValue('nfLimit', '')
																	changeCustomDisableFields('nfLimit', !customDisableFields?.nfLimit)

																	if (watchFields?.unlimitedItems && watchFields?.unlimitedNF) {
																		setValue('partialConsumption', false)
																		setValue('displayBlockedValue', false)
																	}
																}}
																selected={value}
															/>
														)}
													/>
												</MuiBox>
											</GridItem>
										</>
									)}

									<GridItem xs={2} sm={6} md={2}>
										<Controller
											name="quantity"
											control={control}
											render={({ value, onChange }) => (
												<MuiInput
													textLabel={watchFields?.quantity ? 'Qtde de itens*' : ' '}
													showLabel
													defaultColor={defaultColor}
													disabled={customDisableFields?.quantity}
													onChange={e => onChange(e)}
													value={value}
													placeholder="Qtde de itens*"
												/>
											)}
										/>
									</GridItem>
									<GridItem sx={2} sm={6} md={2}>
										<MuiBox
											sx={{
												label: {
													marginLeft: '0px!important',
													marginRight: '0px!important',
												},
											}}
										>
											<Controller
												name="unlimitedItems"
												control={control}
												render={({ value, onChange }) => (
													<MuiCheckbox
														labeltxt={t('CREATE_NEGOTIATION.UNLIMITED_ITEMS')}
														// disabled={customDisableFields?.unlimitedItems}
														onChange={e => {
															onChange(e)
															setValue('quantity', null)
															changeCustomDisableFields('quantity', !customDisableFields?.quantity)
															setValue('quantity', '')

															if (watchFields?.unlimitedItems && watchFields?.unlimitedNF) {
																setValue('partialConsumption', false)
																setValue('displayBlockedValue', false)
															}
														}}
														selected={value}
													/>
												)}
											/>
										</MuiBox>
									</GridItem>

									<GridItem sx={4} sm={12} md={4}>
										<MuiBox
											sx={{
												label: {
													marginLeft: '0px!important',
													marginRight: '0px!important',
												},
											}}
										>
											<Controller
												name="partialConsumption"
												control={control}
												render={({ onChange }) => (
													<MuiCheckbox
														defaultColor={defaultColor}
														hasDashed
														tooltipContent="O participante poderá ter o saldo liberado conforme a quantidade de produtos adquiridos.."
														hasTolltipLabel
														labeltxt="Liberação de saldo parcial"
														disabled={watchFields?.unlimitedNF && watchFields?.unlimitedItems}
														onChange={e => {
															onChange(e)
															changeCustomDisableFields('unlimitedItems', !customDisableFields?.unlimitedItems)
														}}
														// selected={value}
														selected={watchFields?.partialConsumption}
														// reset={watchFields?.unlimitedNF && watchFields?.unlimitedItems}
													/>
												)}
											/>
										</MuiBox>
									</GridItem>
								</GridContainer>
							)}
							<GridContainer spacing={3}>
								<GridItem lg={3} mt={5} mb={4}>
									<MuiBox
										sx={{
											label: {
												marginLeft: '0px!important',
												marginRight: '0px!important',
											},
										}}
									>
										<Controller
											name="displayBlockedValue"
											control={control}
											render={({ onChange }) => (
												<MuiCheckbox
													defaultColor={defaultColor}
													labeltxt={t('CREATE_NEGOTIATION.BALANCE_TRADING')}
													hasDashed
													tooltipContent="Permite que o saldo da negociação seja exibido em saldos bloqueados"
													hasTolltipLabel
													disabled={(watchFields?.unlimitedNF && watchFields?.unlimitedItems) || watchFields?.unlimitedItems}
													onChange={e => {
														onChange(e)
													}}
													selected={watchFields?.displayBlockedValue}
													// selected={value}
													reset={watchFields?.unlimitedNF && watchFields?.unlimitedItems}
												/>
											)}
										/>
									</MuiBox>
								</GridItem>
								{watchFields?.unlimitedItems && watchFields?.displayBlockedValue && (
									<GridItem sm={12} lg={9} md={12} mt={4}>
										<LimitInformation />
									</GridItem>
								)}
							</GridContainer>
							{/******* Benefit and value ********/}
							<GridContainer alignItems="end" spacing={2}>
								<GridItem xs={4} sm={12} md={4}>
									<Controller
										control={control}
										name="benefit"
										render={({ onChange, value }) => (
											<MuiAutocomplete
												textLabel={watchFields?.benefit?.label ? t('CREATE_NEGOTIATION.BENEFIT') : ' '}
												showLabel
												disabled={customDisableFields?.benefit}
												backgroundColor={customDisableFields?.benefit && '#F2F2F2'}
												defaultColor={defaultColor}
												showError={errorMessage && hasActiveNegotiation}
												error={errorMessage && hasActiveNegotiation}
												errorText={hasActiveNegotiation && errorMessage && validationMessages[errorMessage]}
												value={value}
												onChange={(_e, newValue) => {
													onChange(newValue)
													validateSelectedGroupItem({
														grouperId: watchFields?.selectGroup?.key || watchFields?.productSKU?.key,
														typeOfGrouper: watchFields?.groups?.value,
														typeOfBenefit: newValue?.value,
													})

													if (!hasActiveNegotiation) {
														changeCustomDisableFields('benefitGroup', false)

														if (newValue?.label && watchFields?.groups?.label !== 'Produto') {
															setValue('benefitGroup', benefitGroupOtpions[0])
															changeCustomDisableFields('value', false)
														}
													}
												}}
												options={benefitOptions}
												placeholder={t('CREATE_NEGOTIATION.BENEFIT')}
											/>
										)}
										defaultValue={[]}
									/>
								</GridItem>
								<GridItem xs={4} sm={12} md={4}>
									<Controller
										control={control}
										name="benefitGroup"
										render={({ onChange, value }) => (
											<MuiAutocomplete
												textLabel={watchFields?.benefitGroup?.label ? t('CREATE_NEGOTIATION.BENEFIT_CONFIGURATION') : ' '}
												showLabel
												disabled={customDisableFields?.benefitGroup}
												backgroundColor={customDisableFields?.benefitGroup && '#F2F2F2'}
												defaultColor={defaultColor}
												value={value}
												onChange={(_e, newValue) => {
													onChange(newValue)
													setValue('value', null)
													changeCustomDisableFields('value', false)
												}}
												options={watchFields?.groups?.label === 'Produto' ? benefitGroupOtpions : defaultBeneftitOption}
												placeholder={t('CREATE_NEGOTIATION.BENEFIT_CONFIGURATION')}
											/>
										)}
										defaultValue={[]}
									/>
								</GridItem>
								<GridItem xs={4} sm={12} md={4}>
									{watchFields?.benefitGroup?.label === 'Percentual' ? (
										<Controller
											name="value"
											control={control}
											render={({ value, onChange }) => (
												<InputPercentage
													textLabel={watchFields?.value ? t('CREATE_NEGOTIATION.PERCENTAGE') : ' '}
													showLabel
													defaultColor={defaultColor}
													disabled={customDisableFields?.value}
													backgroundColor={customDisableFields?.value && '#F2F2F2'}
													onChange={e => {
														onChange(e)
														changeCustomDisableFields('sellerName', false)
														changeCustomDisableFields('description', false)
														changeCustomDisableFields('transferValidAfter', false)
													}}
													value={value}
													placeholder={t('CREATE_NEGOTIATION.PERCENTAGE')}
												/>
											)}
										/>
									) : (
										<Controller
											name="value"
											control={control}
											render={({ value, onChange }) => (
												<>
													{watchFields?.benefit?.label === 'Cashback' ? (
														<InputPtBRCurrency
															textLabel={watchFields?.value ? t('CREATE_NEGOTIATION.VALUE') : ' '}
															showLabel
															defaultColor={defaultColor}
															disabled={customDisableFields?.value}
															backgroundColor={customDisableFields?.value && '#F2F2F2'}
															onChange={e => {
																onChange(e)
																changeCustomDisableFields('sellerName', false)
																changeCustomDisableFields('description', false)
																changeCustomDisableFields('transferValidAfter', false)
															}}
															value={value}
															placeholder={t('CREATE_NEGOTIATION.VALUE')}
														/>
													) : (
														<InputUSDCurrency
															textLabel={watchFields?.value ? t('CREATE_NEGOTIATION.VALUE') : ' '}
															showLabel
															defaultColor={defaultColor}
															disabled={customDisableFields?.value}
															backgroundColor={customDisableFields?.value && '#F2F2F2'}
															onChange={e => {
																onChange(e)
																changeCustomDisableFields('sellerName', false)
																changeCustomDisableFields('description', false)
																changeCustomDisableFields('transferValidAfter', false)
															}}
															value={value}
															placeholder={t('CREATE_NEGOTIATION.VALUE')}
														/>
													)}
												</>
											)}
										/>
									)}
								</GridItem>
							</GridContainer>
							<GridContainer alignItems="end" spacing={2} mt={2}>
								{watchFields?.benefit?.label === 'Cashback' && (
									<GridItem lg={4} mt={3} mb={3}>
										<MuiBox
											sx={{
												label: {
													marginLeft: '0px!important',
													marginRight: '0px!important',
												},
											}}
										>
											<Controller
												name="allowTransfer"
												control={control}
												render={({ value, onChange }) => (
													<MuiCheckbox
														defaultColor={defaultColor}
														labeltxt="Disponibilizar transferência"
														disabled={customDisableFields?.selectGroup}
														onChange={e => {
															onChange(e)
														}}
														selected={value}
													/>
												)}
											/>
										</MuiBox>
									</GridItem>
								)}
								{watchFields?.benefit?.label === 'Cashback' && (
									<GridItem
										lg={4}
										sx={{
											'& .MuiStack-root': {
												paddingTop: '0px!important',
											},
										}}
									>
										<Controller
											control={control}
											name="transferValidAfter"
											render={({ onChange, ...field }) => (
												<MuiDatePicker
													{...field}
													defaultColor={defaultColor}
													showLabel
													autoComplete="off"
													textLabel="Data de transferência*"
													// disabled={customDisableFields?.transferValidAfter}
													minDate={watchFields?.startDate || format(new Date(), 'yyyy-MM-dd')}
													onChange={value => {
														if (value) {
															onChange(new Date(value).toISOString())
															changeCustomDisableFields('sellerName', false)
															changeCustomDisableFields('description', false)
														}
													}}
												/>
											)}
										/>
									</GridItem>
								)}
								<GridItem xs={4} sm={12} md={4}>
									<Controller
										name="sellerName"
										control={control}
										render={({ value, onChange }) => (
											<MuiInput
												textLabel={watchFields?.sellerName ? t('CREATE_NEGOTIATION.SELLER_NAME') : ' '}
												showLabel
												defaultColor={defaultColor}
												// disabled={customDisableFields?.sellerName}
												onChange={e => {
													onChange(e)
													changeCustomDisableFields('description', false)
												}}
												InputProps={{
													maxLength: 80,
												}}
												value={value}
												placeholder={t('CREATE_NEGOTIATION.SELLER_NAME')}
											/>
										)}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} mt={3}>
									<Controller
										name="description"
										control={control}
										render={({ value, onChange }) => (
											<MuiBox
												sx={{
													width: '100%',
													height: '100px',
													'&::-moz-placeholder': {
														color: '#AAA',
													},
													'&::-webkit-input-placeholder': {
														color: '#AAA',
													},
													'&::placeholder': {
														color: '#AAA',
													},
													'& textarea': {
														border: '1px solid #BDBDBD',
														padding: '20px',
														borderRadius: '5px',
														width: '100%',
														maxWidth: '100%',
														minWidth: '100%',
														maxHeight: '100%',
														minHeight: '100%',
														fontFamily: 'Roboto, Helvetica, Arial ,sans-serif',
														fontSize: '15px',
														// backgroundColor: customDisableFields?.description && '#F2F2F2',
													},
												}}
											>
												<textarea
													// disabled={customDisableFields?.description}
													placeholder={t('CREATE_NEGOTIATION.DESCRIBE_NEGOTIATION')}
													value={value}
													onChange={onChange}
													maxLength={350}
												/>
												{/* <TextareaAutosize
													placeholder={t('CREATE_NEGOTIATION.DESCRIBE_NEGOTIATION')}
													value={value}
													onChange={onChange}
													maxLength={350}
													sx={{
														'&::-moz-placeholder': {
															color: '#A6A6A6',
														},
														'&::-webkit-input-placeholder': {
															color: '#A6A6A6',
														},
														'&::placeholder': {
															color: '#A6A6A6',
														},
													}}
												/> */}
											</MuiBox>
										)}
									/>
								</GridItem>
							</GridContainer>
						</>
					)}
				</MuiBox>
			</form>
		</MuiBox>
	)
}
