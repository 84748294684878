import React from 'react'
import * as _ from 'lodash'
// import { NumberFormat } from 'react-number-format'
import { NumericFormat } from 'react-number-format'
import { createTheme } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { GetSponsorByCnpj } from '../redux/api/sponsor'
import moment from 'moment'
import { envs } from '../redux/api/windowServerData'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export function convertUTCToLocal(utcDt, utcDtFormat) {
	var toDt = moment.utc(utcDt, utcDtFormat).toDate()
	return moment(toDt).format('YYYY-MM-DD HH:mm:ss')
}

export const numberFormatTextPercentage = (_data, _prefix, _toFix) => {
	let _toFixed = _toFix === undefined ? 0 : _toFix
	let tSeparator = '.'
	let dSeparator = ','
	if (localStorage.getItem('i18nextLng') != null) {
		if (localStorage.getItem('i18nextLng') === 'pt-BR' || localStorage.getItem('i18nextLng') === 'es') {
			tSeparator = '.'
			dSeparator = ','
		} else {
			tSeparator = ','
			dSeparator = '.'
		}
	}
	let v = _toFixed === 0 ? _.toInteger(_data) : Number(_data).toFixed(_toFixed)
	return (
		<NumericFormat
			value={v}
			displayType={'text'}
			thousandSeparator={tSeparator}
			decimalSeparator={dSeparator}
			decimalScale={_toFixed}
			suffix="%"
			prefix={_prefix}
			isNumericString={true}
		/>
	)
}
export const numberFormatText = (_data, _prefix, _toFix) => {
	let _toFixed = _toFix === undefined ? 0 : _toFix
	let tSeparator = '.'
	let dSeparator = ','
	if (localStorage.getItem('i18nextLng') != null) {
		if (localStorage.getItem('i18nextLng') === 'pt-BR' || localStorage.getItem('i18nextLng') === 'es') {
			tSeparator = '.'
			dSeparator = ','
		} else {
			tSeparator = ','
			dSeparator = '.'
		}
	}
	let v = _toFixed === 0 ? _.toInteger(_data) : Number(_data).toFixed(_toFixed)
	return (
		<NumericFormat
			value={v}
			displayType={'text'}
			thousandSeparator={tSeparator}
			decimalSeparator={dSeparator}
			decimalScale={_toFixed}
			prefix={_prefix}
			isNumericString={true}
		/>
	)
}

export const pointsFormatText = (_data, _prefix, _toFix) => {
	let _toFixed = _toFix === undefined ? 0 : _toFix
	let tSeparator = '.'
	if (localStorage.getItem('i18nextLng') != null) {
		if (localStorage.getItem('i18nextLng') === 'pt-BR' || localStorage.getItem('i18nextLng') === 'es') {
			tSeparator = '.'
		} else {
			tSeparator = '.'
		}
	}
	let v = _toFixed === 0 ? _.toInteger(_data) : Number(_data).toFixed(_toFixed)
	return (
		<NumericFormat
			value={v}
			displayType={'text'}
			thousandSeparator={tSeparator}
			decimalSeparator={tSeparator}
			decimalScale={_toFixed}
			prefix={_prefix}
			isNumericString={true}
		/>
	)
}

export const themeUtil = () => {
	return createTheme({
		overrides: {
			MuiButtonBase: {
				// Name of the component ⚛️ / style sheet
				root: {
					// Name of the rule
					border: '1px solid green !important',
					borderRadius: '3px',
					padding: '0 12px 0 12px !important',
					maxWidth: '32px !important',
					height: '33px',
					margin: '3px',
					'&:hover': {
						background: 'green !important',
						color: 'white',
					},
				},
			},
		},
		palette: {
			primary: {
				main: grayColor[2],
			},
		},
		typography: {
			useNextVariants: true,
		},
	})
}

export const removeCNPJmask = cnpj => {
	const noMaskCnpj = cnpj.replace(/\D/g, '')
	noMaskCnpj.replace(/[^\d]+/g, '')
	return noMaskCnpj
}

export const VerifyCPF = cpf => {
	let valido = true
	cpf = cpf?.replace(/[^\d]+/g, '')
	if (cpf === '') valido = false
	// Elimina CPFs invalidos conhecidos
	if (
		cpf?.length !== 11 ||
		cpf === '00000000000' ||
		cpf === '11111111111' ||
		cpf === '22222222222' ||
		cpf === '33333333333' ||
		cpf === '44444444444' ||
		cpf === '55555555555' ||
		cpf === '66666666666' ||
		cpf === '77777777777' ||
		cpf === '88888888888' ||
		cpf === '99999999999'
	) {
		// valido = false
		return false
	}
	// Valida 1o digito
	let add = 0
	for (let i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i)) * (10 - i)
	}
	let rev = 11 - (add % 11)
	if (rev === 10 || rev === 11) {
		rev = 0
	}
	if (rev !== parseInt(cpf.charAt(9))) {
		valido = false
	}
	// Valida 2o digito
	add = 0
	for (let i = 0; i < 10; i++) {
		add += parseInt(cpf.charAt(i)) * (11 - i)
	}
	rev = 11 - (add % 11)
	if (rev === 10 || rev === 11) {
		rev = 0
	}
	if (rev !== parseInt(cpf.charAt(10))) {
		valido = false
	}
	//

	return valido
}

export const verifyAlreadyCnpj = cnpj => {
	GetSponsorByCnpj(cnpj).then(response => {
		if (!response.success) {
			return true
		} else {
			return false
		}
	})
}

export const VerifyCNPJ = cnpj => {
	if (cnpj) {
		let valido = true
		cnpj = cnpj.replace(/[^\d]+/g, '')
		if (cnpj === '') return false
		if (cnpj.length !== 14) {
			valido = false
		}

		// Elimina CNPJs invalidos conhecidos
		if (
			cnpj === '00000000000000' ||
			cnpj === '11111111111111' ||
			cnpj === '22222222222222' ||
			cnpj === '33333333333333' ||
			cnpj === '44444444444444' ||
			cnpj === '55555555555555' ||
			cnpj === '66666666666666' ||
			cnpj === '77777777777777' ||
			cnpj === '88888888888888' ||
			cnpj === '99999999999999'
		) {
			valido = false
		}

		// Valida DVs
		let tamanho = cnpj.length - 2
		let numeros = cnpj.substring(0, tamanho)
		let digitos = cnpj.substring(tamanho)
		let soma = 0
		let pos = tamanho - 7
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--
			if (pos < 2) {
				pos = 9
			}
		}
		let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
		if (resultado !== digitos.charAt(0)) {
			valido = false
		}

		tamanho = tamanho + 1
		numeros = cnpj.substring(0, tamanho)
		soma = 0
		pos = tamanho - 7
		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--
			if (pos < 2) {
				pos = 9
			}
		}
		resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
		if (resultado !== digitos.charAt(1)) {
			valido = false
		}
		//
		if (valido) {
			// //console.log('O CNPJ INFORMADO É VÁLIDO.');
		} else {
			// //console.log("o CNPJ é inválido");
		}
		return valido
	}
}

export const FormatCPF = cpf => {
	return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2)
}

export const FormatCNPJ = cnpj => {
	cnpj = cnpj.replace(/[^\d]+/g, '')
	//cnpj.substr(0, 2) + "." + cnpj.substr(2, 3) + "." + cnpj.substr(5, 3) + "/" + cnpj.substr(9, 4) + "-" + cnpj.substr(12, 2);
	return cnpj.substr(0, 2) + '.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '/' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2)
}

export const formatDate = date => {
	if (!date) return ''
	const formattedDate = format(new Date(date), 'MM-dd-yyyy')

	return formattedDate
}

export const CNPJVerify = cnpj => {
	//let valido = true

	cnpj = cnpj.replace(/[^\d]+/g, '')
	if (cnpj === '') return false
	if (cnpj.length !== 14) {
		return false
	}
	//console.log('cnpj: ', cnpj)
	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj === '00000000000000' ||
		cnpj === '11111111111111' ||
		cnpj === '22222222222222' ||
		cnpj === '33333333333333' ||
		cnpj === '44444444444444' ||
		cnpj === '55555555555555' ||
		cnpj === '66666666666666' ||
		cnpj === '77777777777777' ||
		cnpj === '88888888888888' ||
		cnpj === '99999999999999'
	) {
		return false
	}

	// Valida DVs
	let tamanho = cnpj.length - 2
	let numeros = cnpj.substring(0, tamanho)
	let digitos = cnpj.substring(tamanho)
	let soma = 0
	let pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--
		if (pos < 2) pos = 9
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
	if (resultado != digitos.charAt(0)) return false

	tamanho = tamanho + 1
	numeros = cnpj.substring(0, tamanho)
	soma = 0
	pos = tamanho - 7
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--
		if (pos < 2) pos = 9
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
	if (resultado != digitos.charAt(1)) return false

	return true
}

export function cnpjValidation(val) {
	if (val.length == 18 || val.length == 14) {
		var cnpj = val.trim()

		cnpj = cnpj.replace(/\./g, '')
		cnpj = cnpj.replace('-', '')
		cnpj = cnpj.replace('/', '')
		cnpj = cnpj.split('')

		var v1 = 0
		var v2 = 0
		var aux = false

		for (var i = 1; cnpj.length > i; i++) {
			if (cnpj[i - 1] != cnpj[i]) {
				aux = true
			}
		}

		if (aux == false) {
			return false
		}

		for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v1 += cnpj[i] * p1
			} else {
				v1 += cnpj[i] * p2
			}
		}

		v1 = v1 % 11

		if (v1 < 2) {
			v1 = 0
		} else {
			v1 = 11 - v1
		}

		if (v1 != cnpj[12]) {
			return false
		}

		for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v2 += cnpj[i] * p1
			} else {
				v2 += cnpj[i] * p2
			}
		}

		v2 = v2 % 11

		if (v2 < 2) {
			v2 = 0
		} else {
			v2 = 11 - v2
		}

		if (v2 != cnpj[13]) {
			return false
		} else {
			return true
		}
	} else {
		return false
	}
}

export function VerifyTelefone(numero) {
	// Remove todos os caracteres não numéricos
	let numeroLimpo = numero.replace(/\D/g, '')

	// Verifica se o número tem a quantidade correta de dígitos
	if (numeroLimpo.length !== 10) {
		return false
	}

	// Verifica se o número começa com um dígito válido para o DDD
	let dddValidos = [
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'21',
		'22',
		'24',
		'27',
		'28',
		'31',
		'32',
		'33',
		'34',
		'35',
		'37',
		'38',
		'41',
		'42',
		'43',
		'44',
		'45',
		'46',
		'47',
		'48',
		'49',
		'51',
		'53',
		'54',
		'55',
		'61',
		'62',
		'63',
		'64',
		'65',
		'66',
		'67',
		'68',
		'69',
		'71',
		'73',
		'74',
		'75',
		'77',
		'79',
		'81',
		'82',
		'83',
		'84',
		'85',
		'86',
		'87',
		'88',
		'89',
		'91',
		'92',
		'93',
		'94',
		'95',
		'96',
		'97',
		'98',
		'99',
	]

	let ddd = numeroLimpo.substring(0, 2)

	if (!dddValidos.includes(ddd)) {
		return false
	}

	// Verifica se o número começa com um dígito válido

	if (numeroLimpo.length === 10 && numeroLimpo.charAt(2) === '9') {
		return false
	}

	// Verifica se o número tem apenas dígitos repetidos (ex: 1111111111)
	let digitosRepetidos = /^(\d)\1+$/
	if (digitosRepetidos.test(numeroLimpo)) {
		return false
	}

	// Se todas as condições anteriores forem atendidas, o número é válido
	return true
}

export const getProgramTitle = subTitle => {
	try {
		if (subTitle) {
			return `Parceiro - ${subTitle}`
		}
		const programName = localStorage.getItem('programName')

		return programName ? `${programName} - Parceiro` : 'Parceiro'
	} catch (error) {
		console.log('Error: ', error)
		return 'Parceiro'
	}
}

export function formatPoints(value, mask) {
	if (typeof value !== 'number') {
		throw new TypeError('O valor deve ser um número.')
	}

	const isDecimal = !Number.isInteger(value)

	const options = isDecimal ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : { minimumFractionDigits: 0, maximumFractionDigits: 0 }

	const formattedValue = value.toLocaleString('en-US', options).replace(',', '.')

	return mask ? `${mask} ${formattedValue}` : formattedValue
}

export function FormarPointsValue(value) {
	const mask = envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL || localStorage.getItem('programCurrencySymbol') || ''
	return formatPoints(value, mask)
}
