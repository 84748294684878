/* eslint-disable */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// @ Styles
import * as S from './styles'

//@ components
import Cards from './components/cards'
import { Controller } from 'react-hook-form'

// @ Functions
import { handleCalculateValue } from './Functions'

// @ Styles and Utils
import { CssTextField } from '../../../../Filter/styles'
import { DefaultProductData } from './Functions/utils'

// @ Mui
import { Autocomplete, CircularProgress, FormLabel, Grid } from '@mui/material'

// @ Components
import Configuration from './components/Configuration'
import CustomModal from '../../../../../../Modal'
import { CreateProductByMultiple, ParameterizationByDivision } from '../../../Functions'
import { ProductByDivision } from './Functions'
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader'
import CalcMemory from '../../../../CalculationMemory'

const ParameterizeDivisionTB = ({
	grouper,
	useData,
	onReset,
	loading,
	viewMode,
	division,
	setLoading,
	isMultiple,
	setViewMode,
	productData,
	snackStatus,
	divisionName,
	programRules,
	setSnackStatus,
	GenerationType,
	circularProgress,
	pametrizationName,
	selectedDivisionIdRed,
	modalParameterization,
	setModalParameterization,
}) => {
	const { t } = useTranslation()

	const [divisionProductData, setDivisionProductData] = useState({})
	const [parameterizationGenerationPoints, setParameterizationGenerationPoints] = useState({})

	const [open, setOpen] = useState({
		active: false,
		row: [],
	})

	const { control, watch, reset, errors } = useData

	const { typeOfRedeem, redemptionPoints } = watch()

	const goToBack = () => {
		onReset()
		setViewMode(true)
	}

	const fetchCalculation = async (points, type) => {
		const redemptionPoints = await handleCalculateValue({
			productId: productData?.id,
			price: divisionProductData?.price || 0,
			typeOfRedeem: typeOfRedeem?.value || type,
			redemptionPoints: redemptionPoints || points || divisionProductData?.redemptionPoints,
		})

		if (redemptionPoints) {
			setParameterizationGenerationPoints(redemptionPoints)
		}
	}

	useEffect(() => {
		selectedDivisionIdRed?.length === 0 &&
			ProductByDivision(division?.id, 'Redeem', productData?.id, setDivisionProductData, setSnackStatus, setLoading).then(response => {
				if (!_.isEmpty(response)) {
					reset({
						typeOfRedeem: response?.typeOfRedeem,
						redemptionPoints: response?.redemptionPoints,
					})
				}
			})
	}, [division, viewMode])

	const handleParameterization = () => {
		const params = {
			goToBack,
			productData,
			typeOfRedeem,
			redemptionPoints,
			setSnackStatus,
			typeOfBenefitRedeem: '2',
			setLoading,
			grouper,
		}

		if (selectedDivisionIdRed?.length > 0) {
			CreateProductByMultiple({
				...params,
				divisionsId: selectedDivisionIdRed,
				pametrizationName,
			})
		} else {
			ParameterizationByDivision({
				...params,
				divisionId: division?.id,
			})
		}

		setModalParameterization({ open: false })
		setViewMode(true)
	}

	const sharedProps = {
		errors: errors,
		control: control,
		setOpen: setOpen,
		viewMode: viewMode,
		productData: productData,
		programRules: programRules,
		typeOfRedeem: typeOfRedeem?.key,
		redemptionPoints: redemptionPoints,
		fetchCalculation: fetchCalculation,
		parameterizationGenerationPoints: parameterizationGenerationPoints,
		calculationMemory: parameterizationGenerationPoints,
	}

	useEffect(() => {
		if (!_.isEmpty(typeOfRedeem)) {
			fetchCalculation()
		}
	}, [typeOfRedeem])

	return (
		<S.CardsContent>
			{loading && <Loader />}
			<CalcMemory
				rows={open.row}
				open={open.active}
				divisionName={divisionName}
				parameterization={productData}
				onClose={() => setOpen({ active: false })}
			/>

			<CustomModal
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => handleParameterization()}
				onClose={() => setModalParameterization({ open: false })}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			{isMultiple && <S.Line />}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Configuração do benefício*')}</span>
						<Controller
							control={control}
							name="typeOfRedeem"
							render={({ value, onChange }) => (
								<Autocomplete
									fullWidth
									value={value}
									disabled={viewMode || circularProgress}
									options={GenerationType}
									onChange={(_event, newValue) => onChange(newValue)}
									getOptionLabel={option => (option.value ? t(option.value) : '')}
									renderInput={params => (
										<CssTextField
											{...params}
											variant="outlined"
											size="small"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{circularProgress && <CircularProgress color="inherit" size={20} />}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
											error={errors?.typeOfRedeem}
											helperText={errors?.typeOfRedeem?.message}
										/>
									)}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				{/* <Grid item xs={12} sm={9}>
					{typeOfRedeem?.key !== '0' && typeOfRedeem?.key !== '1' && <Cards {...sharedProps} />}
				</Grid> */}
			</Grid>
			<Grid item xs={12} sm={12}>
				{typeOfRedeem?.key === '3' && <Configuration {...sharedProps} />}
			</Grid>
		</S.CardsContent>
	)
}

ParameterizeDivisionTB.propTypes = {
	grouper: PropTypes.number,
	loading: PropTypes.bool,
	onReset: PropTypes.func,
	viewMode: PropTypes.bool,
	useData: PropTypes.object,
	isMultiple: PropTypes.bool,
	setLoading: PropTypes.func,
	division: PropTypes.object,
	setViewMode: PropTypes.func,
	productData: PropTypes.object,
	snackStatus: PropTypes.object,
	setSnackStatus: PropTypes.func,
	divisionName: PropTypes.string,
	circularProgress: PropTypes.bool,
	programRules: PropTypes.object,
	GenerationType: PropTypes.array,
	pametrizationName: PropTypes.string,
	selectedDivisionIdRed: PropTypes.string,
	selectedDivisionIdGen: PropTypes.string,
	modalParameterization: PropTypes.object,
	setModalParameterization: PropTypes.func,
}

ParameterizeDivisionTB.defaultProps = {
	productData: DefaultProductData,
	isMultiple: false,
}

export default ParameterizeDivisionTB
