/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { MuiLoader } from "../../Loader";
import { ModalErrorIcon } from "../../Icons";
import { MuiBox } from "../../Box";
export function CustomTextButton(_a) {
    var props = __rest(_a, []);
    var text = props.text, disabled = props.disabled, textColor = props.textColor, defaultColor = props.defaultColor, borderColor = props.borderColor, hoverBorderColor = props.hoverBorderColor, hoverColor = props.hoverColor, hoverTextColor = props.hoverTextColor, rounded = props.rounded, customSize = props.customSize, isLoading = props.isLoading, loaderColor = props.loaderColor, borderRadius = props.borderRadius, icon = props.icon, colorIcon = props.colorIcon, sx = props.sx;
    return (_jsxs(Button, __assign({ variant: "outlined", fullWidth: true, sx: __assign(__assign({}, sx), { backgroundColor: (disabled && "#C8C8C8") || defaultColor || "#E2E2E2", border: "1px solid ".concat(borderColor !== null && borderColor !== void 0 ? borderColor : "transparent", "!important"), color: (disabled && "#fff!important") || textColor || "#A2A2A2", fontSize: "14px", padding: "8px 15px", textTransform: "none", borderRadius: borderRadius ? borderRadius : 0 || rounded ? "30px" : 0, width: customSize && "".concat(customSize, "!important"), minWidth: customSize || (isLoading && "100px") || "auto", "&:hover": {
                backgroundColor: hoverColor
                    ? "".concat(hoverColor, "!important")
                    : defaultColor,
                color: hoverTextColor ? hoverTextColor : textColor,
                border: "1px solid ".concat(hoverBorderColor !== null && hoverBorderColor !== void 0 ? hoverBorderColor : hoverTextColor, "!important"),
                transition: "0.3s",
            } }) }, props, { children: [icon && (_jsx(MuiBox, __assign({ display: "flex", alignItems: "center", mr: 1 }, { children: _jsx(ModalErrorIcon, { size: 20, color: colorIcon }) }))), isLoading ? _jsx(MuiLoader, { size: 25, defaultColor: loaderColor }) : text] })));
}
