/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ActiveButton } from "./ActiveButton";
import { ConfirmButton } from "./ConfirmButton";
import { CustomizableIconButton } from "./CustomIconButton";
import { InactiveButton } from "./InactiveButton";
import { TextButton } from "./TextButton";
import { ViewButton } from "./ViewButton";
import { CancelButton, CleanButton, CloseButton } from "../Buttons/CancelButton";
import { CustomTextButton } from "./CustomTextButton";
import { DefaultButton } from "./DefaultButton";
export function Button(_a) {
    var typeVariation = _a.typeVariation, props = __rest(_a, ["typeVariation"]);
    var buttonVariations = {
        ConfirmButton: _jsx(ConfirmButton, __assign({ "data-testid": "confirm-button" }, props)),
        ActiveButton: _jsx(ActiveButton, __assign({ "data-testid": "active-button" }, props)),
        CloseButton: _jsx(CloseButton, __assign({ "data-testid": "close-button" }, props)),
        CancelButton: _jsx(CancelButton, __assign({ "data-testid": "cancel-button" }, props)),
        CleanButton: _jsx(CleanButton, __assign({ "data-testid": "clean-button" }, props)),
        CustomIconButton: _jsx(CustomizableIconButton, __assign({ "data-testid": "custom-icon-button" }, props)),
        InactiveButton: _jsx(InactiveButton, __assign({ "data-testid": "inactive-button" }, props)),
        TextButton: _jsx(TextButton, __assign({ "data-testid": "text-button", themeColor: "", themeHoverColor: "" }, props)),
        ViewButton: _jsx(ViewButton, __assign({ "data-testid": "view-button", themeColor: "" }, props)),
        CustomTextButton: _jsx(CustomTextButton, __assign({ "data-testid": "custom-text-button" }, props)),
        DefaultButton: _jsx(DefaultButton, __assign({ "data-testid": "default-button" }, props)),
    };
    if (typeVariation && buttonVariations[typeVariation]) {
        return buttonVariations[typeVariation];
    }
    return null;
}
