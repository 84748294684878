import React from 'react'
import { MuiBox, Table } from '../../../../../components/storybook'
import { useTranslation } from 'react-i18next'
import { TableColumns } from '../../Mocs/TableColumns'
import { getDefaultColor } from '../../../../Partner/PartnerProfile/Functions'

export function TableNegotiations({
	tablePagination,
	negotiationData,
	onNotification,
	onCancel,
	handleDetails,
	getPaginatedNegotiations,
	isLoadingData,
	isFilterData,
}) {
	const { t } = useTranslation()
	const defaultColor = getDefaultColor()
	return (
		<MuiBox
			sx={{
				marginTop: '30px',
				'& td:nth-child(2), th:nth-child(2)': {
					textAlign: 'left!important',
				},
				'& th:nth-child(4)': {
					padding: '5px 0px!important',
					width: '170px!important',
				},
				'& td:nth-child(4)': {
					padding: '5px 0px!important',
					width: '170px!important',
				},
			}}
		>
			<Table
				defaultColor={defaultColor}
				loading={isLoadingData}
				padding="10px 16px!important"
				columns={TableColumns({ onNotification, onCancel, handleDetails })}
				data={negotiationData}
				totalItems={tablePagination?.totalPages}
				notFoundMessage={isFilterData ? 'Nenhuma negociação foi encontrada' : 'Nenhuma negociação a listar'}
				defaultRows={10}
				onChangePage={page => {
					getPaginatedNegotiations('page', page, tablePagination)
				}}
				changeRowsPerPage={rowsPerPage => {
					getPaginatedNegotiations('pageSize', rowsPerPage, tablePagination)
				}}
				onChangeRowsPerPage={() => {}}
				showPagination
			/>
		</MuiBox>
	)
}
