// Material
import { Grid } from '@material-ui/core'
// Components
import Section from 'components/Section'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { numberFormatText, convertUTCToLocal } from 'utils/utils'
import Loader from 'components/Loader/Loader.jsx'
import { ShowLoader, HideLoader } from 'redux/actions'

import { GetOperationDetail, ReleaseResidualBalance } from '../../../../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../../../../redux/api/user'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import { ModalConfirmPassword } from '../ModalConfirmPassword'
import * as S from './styles'
import { useDispatch } from 'react-redux'

import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import { CloseButton } from '../../../../../../components/ConfirmModal/styles'
import {envs}  from '../../../../../../redux/api/windowServerData'



export function ModalResidual({ isOpen, residualData }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [reservedValues, setReservedValues] = useState(null)
	const [isPwdModalOpen, setIsPwdModalOpen] = useState(false)
	const [enableResidualBalanceRelease, setEnableResidualBalanceRelease] = useState(false)
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const { closeResidual, setValueTypeFilter, openSnack, openErrorSnack, refreshBalance, setRefreshTBalance } = useAccounts()

	async function handleConfirmPasswordModal(data) {
		setIsPwdModalOpen(false)

		const partnerId = await GetCurrentPartner().then(res => {
			return res.id
		})

		const body = {
			bankAccountReserveId: residualData.bankAccountReserveId,
			partnerId: partnerId,
		}

		let res
		try {
			dispatch(ShowLoader(<Loader />))
			res = await ReleaseResidualBalance(body)
		} catch (error) {
			console.error(error)
		} finally {
			dispatch(HideLoader)
		}

		if (res.data.success === true) {
			openSnack()
			setRefreshTBalance(!refreshBalance)
		} else {
			openErrorSnack(res.data.errors[0].message)
		}

		setValueTypeFilter({
			dateFilterFrom: null,
			dateFilterTo: null,
			accountName: '',
			status: '',
		})

		closeResidual()
	}

	const handleGetResidualData = useCallback(async () => {
		if (residualData.bankAccountId) {
			const res = await GetOperationDetail(residualData.id)

			let response = res && res.data && res.data.data ? res.data.data : null

			if (response) {
				setReservedValues(response)
				setEnableResidualBalanceRelease(response.enableResidualBalanceRelease)
			}
		}
	}, [residualData])

	useEffect(() => {
		handleGetResidualData()
	}, [residualData])

	return (
		<Layout isOpen={isOpen}>
			<ModalConfirmPassword
				text={`Tem certeza que deseja liberar o saldo de ${reservedValues &&
					reservedValues.residualValue &&
					reservedValues.residualValue.toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL',
					})} ?`}
				subText={`O saldo liberado será disponibilizado para novas reservas.`}
				open={isPwdModalOpen}
				onCancel={() => setIsPwdModalOpen(false)}
				onConfirm={value => handleConfirmPasswordModal(value)}
			/>
			<S.ContainForm>
				<S.SectionBox>
					<Section title={t('PERFORMANCE_CAMPAIGN_CARD_MOVIMENTATION')} style={{ flexGrow: 1 }} />
					<CloseButton>
						<button class="closeButton" onClick={() => closeResidual()}>
							<i className="icon-ico_cancelar" /> Fechar
						</button>
					</CloseButton>
				</S.SectionBox>

				<Grid container spacing={2} xs={12} sm={12}>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>Data</S.Title>
						<div>{moment(convertUTCToLocal(residualData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}</div>
						<div>{moment(convertUTCToLocal(residualData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('HH:mm')}</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>Operação</S.Title>
						<S.TitleStyled>
							<h6>{residualData.operation && t(residualData.operation.value)}</h6>
							<h6>{residualData.description}</h6>
						</S.TitleStyled>
					</Grid>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>Status</S.Title>
						<div>{residualData.status && t(residualData.status.value)}</div>
					</Grid>

					<Grid item xs={3}></Grid>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>Valor reservado</S.Title>
						<div>
							{reservedValues &&
								numberFormatText(
									reservedValues.reservedValue ? reservedValues.reservedValue : '0',
									localStorage.getItem('currencySymbol')
										? `${localStorage.getItem('currencySymbol')}\n`
										: // : envs.REACT_APP_CURRENCY_SYMBOL,
										  envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								)}
						</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>{reservedValues && reservedValues.refundedValue ? 'Valor de estorno' : 'Valor debitado'}</S.Title>
						<div>
							{reservedValues &&
								(reservedValues.refundedValue
									? numberFormatText(
											reservedValues.refundedValue ? reservedValues.refundedValue : '0',
											// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											2,
									  )
									: numberFormatText(
											reservedValues.debitedValue ? reservedValues.debitedValue : '0',
											// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											2,
									  ))}
						</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<S.Title themeColor={themeColor}>Valor residual</S.Title>
						<div
							style={{
								display: 'flex',
							}}
						>
							<S.IconIcoResidualGrid className="icon-ico_residual_grid" />
							<p>
								{reservedValues &&
									numberFormatText(
										reservedValues.residualValue ? reservedValues.residualValue : '0',
										// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
										localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
							</p>
						</div>
					</Grid>
					<Grid item xs={3} sm={3} className="grid_liberar_saldo">
						<S.IconAcaoLiberarSaldoBox>
							<S.IconAcaoLiberarSaldo
								themeColor={(() => {
									if (themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color) {
										return themeColor.visual.icons.color + '!important'
									}
									return `#fff`
								})()}
								className="icon-acao_liberar_saldo"
							/>
							<S.Title
								themeColor={themeColor}
								style={{
									marginLeft: 10,
									cursor: 'pointer',
									borderBottom: `1px dotted ${() => {
										if (themeColor && themeColor.structure && themeColor.structure.backgroundColor) {
											return themeColor.structure.backgroundColor + `!important`
										}
										return `#fff`
									}}`,
								}}
								onClick={() => setIsPwdModalOpen(true)}
							>
								<span
									style={{
										color: 'inherit',
									}}
								>
									Liberar saldo residual
								</span>
							</S.Title>
						</S.IconAcaoLiberarSaldoBox>
					</Grid>
				</Grid>
			</S.ContainForm>
		</Layout>
	)
}
