import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { CalculateValue } from 'redux/api/product'
import { CreateGrouperByMultipleDivisions, GetDivisionByGrouper, UpdateGrouperByDivision } from '../../../../../../../../../../redux/api/Grouper'
import Cookies from 'js-cookie'
import {envs}  from '../../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async setGenerationType => {
	try {
		const res = await GetTypeOfPoints()
		if (res && typeof res === 'object') {
			const newRes = res?.map(item => {
				if (item?.key === '1') {
					return {
						...item,
						value: 'Não gera cashback',
					}
				}
				return item
			})
			setGenerationType(newRes)
		}
	} catch (error) {
		setGenerationType([])
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const handleProductDataUpdate = (productData, setProductDataState, setValue) => {
	if (productData) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = productData

		setValue('typeOfPoints', typeOfPoints)
		setProductDataState({
			parameterization: parameterizationGenerationPoints,
			pointsValue: pointsValue,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	try {
		const res = await CalculateValue(productId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const GetBranchesPlatforms = async ({ Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, setDefaultValues, id, setLoading }) => {
	setLoading(true)
	try {
		const branchesPlatforms = await GetDivisionByGrouper(Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, id)
		if (branchesPlatforms?.status === 200) {
			const { data } = branchesPlatforms
			setDefaultValues({
				...data,
				page: data?.currentPage,
			})
			return data?.results ?? []
		}
	} catch (error) {
		console.error(error)
	} finally {
		setLoading(false)
	}
}

export const ParameterizationByDivision = async ({
	onReset,
	grouper,
	productData,
	typeOfPoints,
	pointsValue,
	setSnackStatus,
	divisionId,
	setLoading,
}) => {
	const partnerId = Cookies.get('partnerId')
	setLoading(true)
	const obj = {
		partnerId: partnerId,
		typeOfGrouper: grouper || '',
		price: productData?.price,
		typeOfBenefit: 'Points',
		typeOfBenefitPoints: 'ByDivision',
		typeOfPoints: typeOfPoints?.value,
		pointsValue: pointsValue,
		divisionId: divisionId,
	}

	const url = window.location.href
	const productId = url.substring(url.lastIndexOf('/') + 1)

	try {
		const res = await UpdateGrouperByDivision(productId, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})
			setTimeout(() => {
				onReset()
			}, 1000)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const MultipleParameterizationByDivision = async ({
	setLoading,
	t,
	onReset,
	productData,
	typeOfPoints,
	pointsValue,
	setSnackStatus,
	selectedDivisionIdGen,
	pametrizationName,
	grouper,
}) => {
	const partnerId = Cookies.get('partnerId')
	const obj = {
		partnerId: partnerId,
		typeOfGrouper: grouper || '',
		price: productData.price,
		typeOfBenefit: 'Points',
		typeOfBenefitPoints: 'ByDivision',
		typeOfPoints: typeOfPoints.value,
		pointsValue: pointsValue,
		pametrizationName: pametrizationName,
		divisionsId: selectedDivisionIdGen,
	}

	const url = window.location.href
	const productId = url.substring(url.lastIndexOf('/') + 1)

	try {
		setLoading(true)
		const res = await CreateGrouperByMultipleDivisions(productId, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				onReset()
			}, 900)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: t(res?.errors[0]?.message),
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export function formatLabelsAndValuesGeneration(item, t) {
	const labels = [
		item?.segmentationType?.value || '',
		t('Forma que gera pontos'),
		t('Preço do produto'),
		t('Taxa de administração'),
		t('Valor bruto'),
		t('Pontos a acumular'),
	]

	const values = [
		item?.name || '-',
		item?.typeOfPoints ? t(item?.typeOfPoints?.value) : '-',
		numberFormatText(
			item?.price ? item?.price : '0',
			// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			2,
		),
		numberFormatText(
			item?.parameterizationGenerationPoints?.customAdministrationFeeApplied
				? item?.parameterizationGenerationPoints?.customAdministrationFeeApplied
				: '0',
			// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			2,
		),
		numberFormatText(
			item?.parameterizationGenerationPoints?.customGrossValue ? item?.parameterizationGenerationPoints?.customGrossValue : '0',
			// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
			2,
		),
		programCurrencySymbol(item),
	].filter(item => item !== null)

	return { labels, values }
}

const programCurrencySymbol = item => {
	if (item?.parameterizationGenerationPoints?.customAccumulatePoints) {
		const sifra = localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '
		return sifra + item?.parameterizationGenerationPoints?.customAccumulatePoints
	} else return '-'
}
